const siteButtonTheme = {
    button: {
        padding: {
            default: {
                h: 0,
            },
        },
    },
};

export default siteButtonTheme;
