const siteTheme = {
    lightMode: {
        site: {
            stage: {
                backgroundColor: '#fcfcfc',
                paddingTop: 64,
            },
            viewport: {
                height: '90vh',
            },
        },
    },
    darkMode: {
        site: {
            stage: {
                backgroundColor: '#262626',
                paddingTop: 64,
            },
            viewport: {
                height: '90vh',
            },
        },
    },
};

export default siteTheme;
