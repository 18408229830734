import siteBaseTheme from './siteBaseTheme';

const siteTypographyTheme = {
    typography: {
        base: {
            fontFamily: '"Heebo", sans-serif',
            fontSizeMin: 14,
        },
        headings: {
            fontFamily: '"Heebo", sans-serif',
            lineHeight: 1.3,
            fontWeight: {
                h1: 400,
                h2: 400,
                h3: 400,
                h4: 400,
                h5: 400,
                h6: 400,
            },
        },
        paragraph: {
            fontWeight: 400,
        },
        colors: {
            light: siteBaseTheme.base.colors.light,
            dark: siteBaseTheme.base.colors.dark,
            muted: siteBaseTheme.base.colors.muted,
        },
    },
};

export default siteTypographyTheme;
