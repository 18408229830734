import siteBaseTheme from './siteBaseTheme';
import siteTheme from './siteTheme';

const siteBaseDarkTheme = {
    base: {
        ...siteBaseTheme.base,
        colors: {
            light: '#1c1c1c',
            dark: '#f1f1f1',
            muted: '#cacaca',
        },
        body: {
            background: siteTheme.darkMode.site.stage.backgroundColor,
        },
        boxShadow: {
            elevationSmall: '0 2px 4px rgba(0,0,0,0.35)',
            elevationDefault: '0 4px 8px rgba(0,0,0,0.35)',
            elevationMedium: '0 8px 16px rgba(0,0,0,0.35)',
            elevationLarge: '0 16px 32px rgba(0,0,0,0.35)',
        },
    },
};

export default siteBaseDarkTheme;
