import siteTheme from './siteTheme';

const siteBaseTheme = {
    base: {
        colors: {
            light: '#f1f1f1',
            dark: '#323232',
        },
        body: {
            background: siteTheme.lightMode.site.stage.backgroundColor,
        },
    },
};

export default siteBaseTheme;
