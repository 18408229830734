import { merge } from 'lodash';
import { baselineTheme } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import { baseTheme, baseDarkTheme } from '@luigiclaudio/ga-baseline-ui/base-theme';
import { buttonTheme, buttonDarkTheme } from '@luigiclaudio/ga-baseline-ui/button';
import { commerceTheme, commerceDarkTheme } from '@luigiclaudio/ga-baseline-ui/commerce';
import { footerTheme, footerDarkTheme } from '@luigiclaudio/ga-baseline-ui/footer';
import { headerTheme, headerDarkTheme } from '@luigiclaudio/ga-baseline-ui/header';
import { typographyTheme, typographyDarkTheme } from '@luigiclaudio/ga-baseline-ui/typography';
import { siteLaunchTheme, siteLaunchDarkTheme } from '@luigiclaudio/ga-baseline-ui/site-launch';
import siteSiteLaunchTheme from './siteSiteLaunchTheme';
import siteSiteLaunchDarkTheme from './siteSiteLaunchDarkTheme';
import siteButtonTheme from './siteButtonTheme';
import siteButtonDarkTheme from './siteButtonDarkTheme';
import siteBaseTheme from './siteBaseTheme';
import siteBaseDarkTheme from './siteBaseDarkTheme';
import siteTypographyTheme from './siteTypographyTheme';
import siteTypographyDarkTheme from './siteTypographyDarkTheme';
import siteTheme from './siteTheme';

const theme = {
    lightMode: merge(baselineTheme.lightMode, {
        base: merge(baseTheme.base, { ...siteBaseTheme.base }),
        button: merge(buttonTheme.button, {
            ...siteButtonTheme.button,
        }),
        commerce: merge(commerceTheme.commerce),
        footer: merge(footerTheme.footer),
        header: merge(headerTheme.header),
        siteLaunch: merge(siteLaunchTheme.siteLaunch, {
            ...siteSiteLaunchTheme.siteLaunch,
        }),
        typography: merge(typographyTheme.typography, { ...siteTypographyTheme.typography }),
        ...siteTheme.lightMode,
    }),
    darkMode: merge(baselineTheme.darkMode, {
        base: merge(baseDarkTheme.base, { ...siteBaseDarkTheme.base }),
        button: merge(buttonDarkTheme.button, {
            ...siteButtonDarkTheme.button,
        }),
        commerce: merge(commerceDarkTheme.commerce),
        footer: merge(footerDarkTheme.footer),
        header: merge(headerDarkTheme.header),
        siteLaunch: merge(siteLaunchDarkTheme.siteLaunch, {
            ...siteSiteLaunchDarkTheme.siteLaunch,
        }),
        typography: merge(typographyDarkTheme.typography, {
            ...siteTypographyDarkTheme.typography,
        }),
        ...siteTheme.darkMode,
    }),
};

export default theme;
