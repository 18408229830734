import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import baselineUIMessagesEnUs from './baselineUIMessagesEnUs';
import siteMessagesEnUs from '../../i18n/siteMessagesEnUs.json';

const messagesEnUs = {
    ...baselineUIMessagesEnUs,
    ...siteMessagesEnUs,
};

const SiteIntlProvider = ({ children }) => {
    return (
        <IntlProvider key="en-US" locale="en-US" messages={messagesEnUs} defaultLocale="en-US">
            {children}
        </IntlProvider>
    );
};

SiteIntlProvider.propTypes = {
    children: PropTypes.node,
};

SiteIntlProvider.defaultProps = {
    children: undefined,
};

export default SiteIntlProvider;
