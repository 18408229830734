const React = require('react');
const Layout = require('./src/components/layout').default;
require('typeface-heebo');

// eslint-disable-next-line react/prop-types
exports.wrapPageElement = ({ element, props }) => {
    // eslint-disable-next-line react/prop-types
    const { location } = props;
    return <Layout location={location}>{element}</Layout>;
};
