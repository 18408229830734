const siteSiteLaunchTheme = {
    siteLaunch: {
        bgStart: '#fcfcfc',
        bgEnd: '#fcfcfc',
        logo: {
            width: 220,
        },
    },
};

export default siteSiteLaunchTheme;
