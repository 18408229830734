import React from 'react';
import PropTypes from 'prop-types';
import { BaselineThemeProvider } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import { ModalProvider } from '@luigiclaudio/ga-baseline-ui/modal';
import SiteIntlProvider from './SiteIntlProvider';
import theme from '../styles/theme';

const Layout = ({ children }) => {
    return (
        <SiteIntlProvider>
            <ModalProvider>
                <BaselineThemeProvider theme={theme}>{children}</BaselineThemeProvider>
            </ModalProvider>
        </SiteIntlProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
